@import "../../styles/var.scss";

.appHeader {
    height: 72px;
    display: flex;
    background: $white;
    border-bottom: $border;

    .logoWrapper {
        width: $sidebarWidth;
        flex: 0 0 $sidebarWidth;
        padding: 0 14px;
        display: flex;
        align-items: center;
    }

    .logoImg {
        width: 45px;
    }

    .logoText {
        width: 105px;
    }

    .logo {
        display: flex;
        align-items: center;
        max-width: 100%;
        gap: 10px;
    }

    .headerRight {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - $sidebarWidth);
        padding-right: 24px;
    }
    @media (max-width: 768px) {
        .logoImg {
            width: 20px;
        }

        .headerRight{
            justify-content: flex-end;
        }

        .headerSearch{
            display: none;
        }

        .logoText {
            width: 60px;
        }
        .logoWrapper {
            width: 120px;
            flex: 0 0 120px;
        }
        .headerRight {
            padding-right: 0;
            width: calc(100% - 130px);
        }
    }
}
