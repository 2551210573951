.avatarItem {
    text-align: center;
    color: #000;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    .img {
        width: 28px;
        height: 28px;
    }
    .title{
        font-size: 9px;
        line-height: 13px;
    }
    .amount{
        font-size: 8px;
        line-height: 11px;
    }
    &:hover{
        cursor: pointer;
        color: #888;
    }
}
