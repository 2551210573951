.graphArea {
    width: calc(100% - 280px);
    overflow: hidden;
    .loadingChart {
        font-size: 24px;
    }
    @media (max-width: 768px){
        width: 100%;
    }
}
.poweredBy {
    position: absolute;
    bottom: 16px;
    right: 20px;
    width: 120px;
}
.loading {
    font-size: 24px;
    width: 100%;
    height: 100%;
    margin: 52px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .loadingIcon {
        font-size: 36px;
        display: block;
    }
}
.messageBtn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-image: url('../../assets/messageIcon.svg'), url('../../assets/mask.svg');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 20;
    cursor: pointer;
    fill: #F4F4F4;
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.12)) drop-shadow(-2px -2px 6px rgba(255, 255, 255, 0.90));
}
.packUp {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-image: url('../../assets/packup.svg'), url('../../assets/mask.svg');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 20;
    cursor: pointer;
    fill: #F4F4F4;
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.12)) drop-shadow(-2px -2px 6px rgba(255, 255, 255, 0.90));
}

.imModal {
    position: absolute;
    bottom: 110px;
    right: 40px;
    width: 800px;
    height: 800px;
    background-color: #fff;
    border-radius: 12px;
    z-index: 20;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .imWarp{
        width: 100%;
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
    }
    input {
        width: 728px;
        height: 60px;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid #C7C9D8;
        background: #FFF;
        margin-bottom: 24px;
        margin-top: 24px;
        font-size: 18px;
        padding-left: 60px;
        padding-right: 60px;
        background-image: url('../../assets/search.svg');
        background-repeat: no-repeat;
        background-position: 20px 17px;
    }
}