@import "../../styles/var.scss";

.searchFilter {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .searchItem {
        display: flex;
        align-items: center;
        color: #fff;
    }
    img {
        width: 12px;
        height: 12px;
    }
    @media (max-width: 768px) {
        gap: 8px;
        font-size: 12px;
    }
}

.searchFilterTopWrapper {
    margin-bottom: 12px;
}

.searchWrapper {
    position: relative;
    width: 100%;
    z-index: 10;
    &.withResult {
        background: #fff;
        border-radius: 15px;
        &.isHeader {
            box-shadow: inset 0px 1px 6px 0px rgba(56, 56, 56, 0.5);
        }
        .search {
            background: none;
        }
        .suggestion {
            background: none;
        }
    }
    &.isHeader {
        width: 84%;
        position: absolute;
        top: 13px;
        z-index: 1;
        .search {
            box-shadow: inset 0px 1px 6px 0px rgba(56, 56, 56, 0.5);
        }
    }
    &.isTrans {
        .search {
            border: 1px solid #fff;
            background: transparent;
            caret-color: white;
            &.withResult{
                caret-color: #000;
            }
        }
        .input {
            background: transparent;
            color: #000;
            &::placeholder {
                color: #b4b4b4;
            }
        }
    }
}

.iconClose {
    width: 24px;
    cursor: pointer;
}

.search {
    background: $white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 10px 18px;
    position: relative;
    overflow: hidden;

    &.withResult {
        border-radius: 15px 15px 0 0;
        box-shadow: unset;
        box-shadow: inset 1px 4px 9px -6px rgba(56, 56, 56, 0.5);
    }
}

.invalidHint {
    color: $red;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
}

.iconSearch {
    width: 26px;
}

.input {
    flex: 1;
    font-size: 15px;
    color: $black;
    line-height: 21px;
    border: none;
    outline: none;
    &.invalid {
        color: $red;
    }
    &::placeholder {
        color: #636363;
    }
}

.searchFilterWrapper {
    padding: 14px 12px 0;
    border-radius: 12px;
    span {
        color: #333;
    }
    // background: #888;
}

.suggestion {
    background: $white;
    border-radius: 0 0 15px 15px;
    max-height: 240px;
    overflow-y: auto;
    padding: 18px;
}

.section {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;

    &:not(:last-child) {
        margin-bottom: 28px;
    }
    .title {
        font-weight: 500;
        color: #636363;
        margin-bottom: 11px;
    }
    .content {
        border-top: 1px solid #d5d5d5;
        border-bottom: 1px solid #d5d5d5;
        padding: 5px 0;
    }
    .item {
        padding: 10px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $black;
        transition: all 0.3s;
        &:hover {
            color: $white;
            background: #ff3300;
            border-radius: 5px;
        }
    }
}

.nameWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}

.icon {
    width: 20px;
    height: 20px;
}

.hint {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #626262;
    line-height: 21px;
}

@media (max-width: 768px){
    .section {
        font-size: 14px;
        line-height: 18px;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        .item {
            div:nth-child(2){
                display: none;
            }
        }
    }
    .searchWrapper {
        &.isHeader {
            width: 100%;
        }
    }
    .iconSearch {
        display: none;
    }
}