.title {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    line-height: 32px;
    margin-bottom: 12px;
    text-transform: capitalize;
}

.configLine {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 16px 0;
    .label {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        line-height: 17px;
        width: 64px;
    }
    .colorList {
        display: flex;
        gap: 12px;
        .color {
            cursor: pointer;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid #575757;
            position: relative;
            &.active {
                &::after {
                    content: "";
                    display: block;
                    border: 0.6px solid #575757;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    transform: scale(1.6);
                }

                // border: 2px solid #000;
            }
        }
    }
    .tagList {
        display: flex;
        gap: 12px;
        .tag {
            cursor: pointer;
            border: 1px solid #979797;
            border-radius: 5px;
            padding: 4px 20px;
            &.active {
                color: #fff;
                background: #ff3300;
            }
        }
    }
}
