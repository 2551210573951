@import "./styles/var.scss";

@font-face {
    font-family: "Syncopate";
    font-weight: 400;
    src: url("./assets/fonts/Syncopate/Syncopate-Regular.ttf");
}

@font-face {
    font-family: "Syncopate";
    font-weight: 800;
    src: url("./assets/fonts/Syncopate/Syncopate-Bold.ttf");
}

@font-face {
    font-family: "Rob";
    src: url("./assets/fonts/robtronika/Robtronika-Regular.ttf");
}

@font-face {
    font-family: "Jura";
    font-weight: 400;
    src: url("./assets/fonts/JURA/Jura-Regular.ttf");
}

@font-face {
    font-family: "Jura";
    font-weight: 800;
    src: url("./assets/fonts/JURA/Jura-Bold.ttf");
}

body {
    scroll-behavior: smooth;
}

.disabled {
    cursor: not-allowed;
}

.nowrap {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entity-config-modal{
    .ant-modal-body{
        border: 1px solid #979797;
    }
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.CodeMirror {
    width: 100%;
    text-align: left;
}

ul,
li {
    margin: 0;
    padding: 0;
}

.container {
    font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "Myriad Pro", "Hiragino Sans GB", "Microsoft YaHei",
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.hover-up {
    transition: transform 0.3s;

    &:hover {
        transform: translateY(-4px);
    }
}

.web3modal-modal-lightbox {
    z-index: 100 !important;
}

.text-left {
    text-align: left;
}

.ant-checkbox-wrapper {
    color: #fff;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background: $red;
    border-color: $red;
}

.ant-checkbox:hover .ant-checkbox-inner {
    border-color: $red;

}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: $red;
}

.ant-checkbox-checked::after {
    border: 1px solid $red;
}

a {
    color: $red;

    &:hover {
        color: darken($color: $red, $amount: 10);
    }
}

.tooltip-white {
    .ant-tooltip-inner {
        color: #333;
        background-color: #fff !important;
    }

    .ant-tooltip-arrow-content {
        background-color: #fff !important;
        --antd-arrow-background-color: unset;
    }
}

input[type="checkbox"] {
    accent-color: #ff3300;
}

.ant-modal {
    .ant-modal-content {
        border-radius: 15px;

        .ant-modal-body {
            padding: 16px 18px;
        }
    }
}

.ant-switch-checked {
    background: #ff3300;
}

// body {
//     margin: 0;
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
//         "Droid Sans", "Helvetica Neue", sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
// }

// #mountNode {
//     text-align: center;
//     canvas {
//         background: #f7f7f7;
//         border-radius: 4px;
//     }
// }

.text-center{
    text-align: center;
}
img {
    max-width: 100%;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1150px;
    }
}

@media (min-width: 1440px) {
    .container {
        max-width: 1300px;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1400px;
    }
}

/*滚动条整体部分,必须要设置*/
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #efeff4;
}

/*滚动条的轨道*/
::-webkit-scrollbar-track {
    box-shadow: inset000pxrgba(240, 240, 240, 0.5);
    border-radius: 10px;
    background: rgba(240, 240, 240, 0.5);
}

/*滚动条的滑块按钮*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset000pxrgba(240, 240, 240, 0.5);
    background: rgba(0, 0, 0, 0.2);
}

/*滚动条的上下两端的按钮*/
::-webkit-scrollbar-button {
    height: 0;
    background-color: #efeff4;
}