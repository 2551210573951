@import "../../styles/var.scss";
.walletTips {
    background: #fff;
    border-radius: 10px;
    padding: 20px 14px;
    background: url('./../../assets/wallet-tips-pics.png') no-repeat center center;
    background-size: 100% 100%;

    .twitter {
        margin-left: 4px;
        margin-top: -4px;
    }

    >.walletTipsDivFist {
        height: 64px;
        padding: 12px 14px;
        background: url('./../../assets/wallet-tips-picf.png') no-repeat center center;
        background-size: 100% 100%;

        >p {
            text-align: left;
            height: 20px;
            line-height: 20px;
            margin: 0;
            font-weight: 700;
            color: #000;

            >span {
                font-family: "Jura";
                font-size: 12px;
                font-weight: 500;
                color: #000;
            }
        }
    }

    .copyIcon {
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin-left: 4px;
    }

    .walletTipsTabs {
        height: 36px;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        >div {
            height: 40px;
            color: rgb(160, 160, 160);
            flex: 1;

            >span {
                font-size: 15px;
                font-weight: 700;
                cursor: pointer;

                &:hover {
                    font-size: 15px;
                    font-weight: 700;
                    color: $red;
                    border-bottom: 2px solid $red;
                }
            }

            >.active {
                color: $red;
                border-bottom: 2px solid $red;
            }
        }
    }

    .walletTipsResult {
        // height: 120px;
        background: #fff;
        border-radius: 10px;

        >div {
            display: flex;
            padding: 15px 10px;
            text-align: left;

            >div {
                flex: 1;
                border-right: 2px solid rgb(223, 223, 223);

                &:last-child {
                    border: none;
                    text-indent: 10px;
                }
            }
        }

        .walletTipsResult_1 {

            p {
                font-size: 14px;
                font-weight: 600;
                margin: 0;

                >span {
                    font-family: "Jura";
                    font-size: 12px;
                    font-weight: 500;
                    color: #000;
                }
            }

            p:nth-child(1) {
                font-size: 16px;
                font-weight: 700;
            }
        }

        .walletTipsResult_2 {

            .level_1,
            .level_2,
            .level_3 {
                cursor: pointer;
            }

            .level_1 {
                font-size: 16px;
                font-weight: 700;
            }

            .level_2 {
                font-size: 14px;
                font-weight: 700;
            }

            .level_3 {
                font-size: 14px;
                font-weight: 600;

                >span {
                    color: rgb(100, 100, 100);
                }
            }

            .level_4 {
                font-size: 14px;
                font-weight: 500;

                >span {
                    font-family: "Jura";
                    color: rgb(160, 160, 160);
                }
            }

            .link {
                a {
                    font-weight: 700;
                    text-decoration: underline;
                    display: inline-block;
                    margin-right: 4px;
                }

                span {
                    font-family: "Jura";
                    color: rgb(160, 160, 160);
                    font-size: 14px;
                }
            }
        }

        .walletTipsResult_3 {
            a {
                font-weight: 700;
                text-decoration: underline;
            }

            span {
                font-family: "Jura";
                color: rgb(160, 160, 160);
                font-size: 14px;
            }
        }
    }

    .arrowTop {
        -moz-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        margin-left: 2px;
        margin-top: -2px;
    }

    .arrowDown {
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(-0deg);
        margin-left: 2px;
        margin-top: -2px;
    }
}