.socialsList {
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    .link {
        text-align: center;
        .icon {
            width: 36px;
        }
        .iconActive {
            width: 36px;
            display: none;
        }

        &:hover {
            .icon {
                display: none;
            }
            .iconActive {
                display: block;
            }
        }
    }
    @media (max-width: 768px){
        flex-direction: row;
        left: 0;
        right: 0;
        justify-content: center;
    }
}
